"use client";

import Icon from "@/app/_components/icon";
import { TagProps, useTags } from "@/app/_components/tags/useTags";
import { cn } from "@/lib/utils";

interface TagComponentProps extends TagProps {
  children: React.ReactNode;
  className?: string;
  iconClassName?: string;
}

const Tag: React.FC<TagComponentProps> = ({
  children,
  className = undefined,
  iconClassName = undefined,
  ...props
}) => {
  const { icon, color, size } = useTags(props);

  return (
    <li
      className={cn(
        "text-semibold flex items-center rounded-sm [&_strong]:font-extrabold",
        !size &&
          "gap-1.5 px-1.5 py-0.5 text-xs tablet:gap-2 tablet:px-2 tablet:py-1 tablet:text-sm",
        size === "xs" && "gap-1.5 px-1.5 py-0.5 text-xs",
        size === "xs" && icon && "pl-1",
        size === "small" && "gap-2 px-2 py-1 text-sm",
        size === "small" && icon && "pl-1",
        size === "large" && "gap-2 px-2 py-1 text-base",
        size === "large" && icon && "pl-1.5",
        !color && "bg-neutral-50 text-text",
        color === "primary" && "bg-primary-50 text-text",
        color === "secondary" && "bg-secondary-100 text-text",
        color === "tertiary" && "bg-tertiary-500 text-white",
        color === "neutral" && "bg-neutral-50 text-text",
        className,
      )}
    >
      {icon && (
        <Icon
          name={icon}
          className={cn(
            "flex-none",
            !size && "h-4 w-4",
            size === "xs" && "h-4 w-4",
            size === "small" && "h-4 w-4",
            size === "large" && "h-5 w-5",
            iconClassName,
          )}
        />
      )}
      <p>{children}</p>
    </li>
  );
};

export default Tag;
